<template>
  <div id="sign">
    <input v-model="name" class="control" placeholder="Prénom"/>
    <VueSignaturePad width="100%" height="350px" ref="signaturePad" />
    <button @click="saveSign">Continue</button>
  </div>
</template>

<script>
import axios from "axios";

export default {
  components: {
  },
  mounted() {
    
  },
  data() {
    return {
      name: null
    }
  },
  methods: {
    saveSign() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();

      if (isEmpty) {
        alert("Merci de demander une signature.")
        return
      }

      const image = data.split(",")
      console.log(isEmpty);

      axios.post('https://ship.transmedicalservice.com/livreur/status', {
        shipCode: this.$route.query.shipCode,
        status: this.$route.query.status,
        nom: this.name,
        sign: image[1],
        authCode: this.$route.query.authCode
      }).then(response => {
        console.log(response)
        this.$router.push({ path: '/end' })
      })
    }
  } 
}
</script>


<style scoped lang="scss">
#sign {
  ::v-deep canvas {
      background-color: #FFF;
  }

  input {
    text-align: center;
    margin-bottom: 20px;

    &::placeholder {
      color: #FFF;
    }
  }

  button {
    margin-top: 20px;
    height: 40px;
  }
}
</style>